@import '../../theme/core.scss';

.root {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    width: 100%;

    @include tablet-or-less {
        flex-direction: column;
        width: 100%;
    }
}

.logo_header {
    margin-bottom: rem(80);
    @include tablet-or-less {
        margin-top: 22px;
    }
}

.illustrationDocDiv {
    @include tablet-or-less {
        display: none;
    }
}

.placeHolderImage {
    width: 72px;
    height: 46px;
}

.filledImage {
    position: absolute;
    width: 200px;
    height: 120px;
}

.continueText{
    font-size: 16px;
    line-height: 22px;
    color: #DF7467;
    cursor: pointer;
}

.qrDownloadSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px 0px;
}

.qrDownloadIcon{
    margin-top: 20px;
}

.downloadButton {
    font: normal normal normal 16px/22px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #DF7467 0% 0% no-repeat padding-box;
    border-radius: 22px;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.continueArrowIcon{
    width: 10px;
    height: 9px;
}

.placeHolderCard {
    height: 144px;
    border: 1px dashed #D4D4D4;
    border-radius: 5px;
    opacity: 1;
    justify-content: center;
    display: flex;
    align-items: center;
}

.root:before {
    content: '';
    right: 0;
    bottom: 0;
    width: 0;
}

.removeIcon{
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: 200px;
    margin-bottom: 118px;
}

.imageBox{
    width: 100%;
    height: 100%;
}

.fullScreenLoader {
    height: 100vh !important;
}

.userRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.leftContainer {
    background-color: $primary-light;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 472px;
    height: 100vh;
    justify-content: center;
    align-items: center;
    @include tablet-or-less {
        width: 100%;
        height: 85px;
        flex-direction: row;
        align-items: unset;
    }

}

.rightContainer {
    width: calc(100% - 472px);
    padding: 0px 75px;
    @include tablet-or-less {
        width: 100%;
        padding: 0 rem(24) rem(24);
    }
    @include breakpoint-down(1120) {
        padding: rem(24);
        flex: 5;
    }
}

.logo {
    width: 62px;
    height: 62px;
    @include tablet-or-less {
        width: 41px;
        height: 41px;
    }
}

.header {
    font-size: rem(40);
    color: white !important;
    text-align: center;
    margin-bottom: rem(60);
    @include tablet-or-less {
        font-size: rem(32);
        margin-bottom: rem(16);
    }
}

.TPCP{
    color: $primary-tp !important;
}

.title {
    cursor: pointer;
    font-weight: bold;
    font-style: normal;
    font-size: rem(24);
    color: $primary;
    text-align: center;
    margin-left: 10px;
    @include tablet-or-less {
        font-size: 18px;
        padding-left: 8px;
    }
}
.userName {
    cursor: pointer;
    font-weight: bold;
    font-style: normal;
    font-size: rem(24);
    text-align: center;
    margin-left: 10px;
    @include tablet-or-less {
        font-size: 18px;
        padding-left: 8px;
    }
}
.primary{
    color: $primary;
}

.description {
    font-size: rem(16);
    line-height: rem(19);
    text-align: center;
    color: white;
    margin-bottom: rem(48);
}

.prompt {
    font-weight: bold;
    font-size: rem(16);
    color: white;
    text-decoration: underline;
    text-align: center;
}

/*STRIPE STYLES*/
.paymentView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 481px;
}
.paymentViewMain{
    width: 100%;
    justify-content: center;
    display: flex;
    height: 100vh;
}

.paymentTabsRow {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
}

.activePaymentTab {
    border-bottom: 1px solid #DF7467;
    text-align: center;
    font: normal normal normal 16px/24px Source Sans Pro;
    letter-spacing: 0px;
    color: #DF7467;
    width: 50%;
}

.inactivePaymentTab {
    border-bottom: 1px solid #F3F3F3;
    text-align: center;
    font: normal normal normal 16px/19px Source Sans Pro;
    letter-spacing: 0px;
    color: #D4D4D4;
    width: 50%;
}

.paymentFormField {
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.checkoutForm {
    width: 418px;
}

.stepHeader {
    color: #242424;
    font-size: 24px;
    font-family: $noto-sans, sans-serif;
    font-weight: 700;
}

.submitButton {
    background: #242424 0% 0% no-repeat padding-box;
    border-radius: 22px;
    font: normal normal normal 16px/22px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 80%;
    height: 44px;
    margin-top: 40px;
}

.loaderDiv{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
