/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

// pink
$pink: #ff3b86;
$light-pink: #fcb8d2;

// purple
$purple: #9d7fe3;
$light-purple: #c3b6e2;

// gray
$gray: #cacaca;
$dark-gray: #333a41;
$light-gray: #1e232b;

// blue
$light-blue: #d2e2fa;

// gradation
$gradient-pink-purple: linear-gradient(125.07deg, $pink 0%, $purple 66.18%);

$primary:   #DF7467;
$primary-dark: #882474;
$primary-light: #FFF7F2;

$primary-tp:   #6A76DE;
$primary-tp-dark: #002AC4;
$primary-tp-light: #D2D2FF;
/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

:export {
    pink: $pink;
    purple: $purple;
    light-purple: $light-purple;
    gray: $gray;
    dark-gray: $dark-gray;
    light-gray: $light-gray;
    light-blue: $light-blue;
    primary: $primary;
    primary-dark: $primary-dark;
    primary-light: $primary-light;
    primary-tp: $primary-tp;
    primary-tp-dark: $primary-tp-dark;
    primary-tp-light: $primary-tp-light;
}
